import cn from 'classnames';
import { Link } from 'react-router-dom';
import classes from './Button.module.scss';
import globals from '@styles/globals.module.scss';

export interface ButtonProps {
    className?: string;
    text?: string;
    onClick?: any;
    link?: string;
}

export const Button = ({
    className,
    text,
    onClick,
    link,
}: ButtonProps): JSX.Element => {
    return (
        <>
            {link ? (
                <Link
                    className={cn(className, classes.root, globals.button)}
                    to={link}
                >
                    {text}
                </Link>
            ) : (
                <button
                    className={cn(className, classes.root, globals.button)}
                    onClick={onClick}
                >
                    {text}
                </button>
            )}
        </>
    );
};
