import * as React from 'react';
import cn from 'classnames';
import classes from './Game.module.scss';

import GameScreen from '@components/GameScreen';
import VariablesIndicatorBar from '@components/VariablesIndicatorBar';

import { GamePageModel } from '@models/pages';

export interface GameProps {
    className?: string;
    config: GamePageModel;
    nNumber: number;
    setNNumber: React.Dispatch<React.SetStateAction<number>>;
    resultsConfig: any;
}

export const Game = ({
    className,
    config,
    nNumber,
    setNNumber,
    resultsConfig,
}: GameProps): JSX.Element => {
    const [activeScreen, setActiveScreen] = React.useState(0);
    const [data, setData] = React.useState({
        R: 0,
        FP: 0,
        NE: 0,
        FL: 0,
        FI: 0,
        FC: 0,
        L: 0,
    });

    React.useEffect(() => {
        console.log({ data });
        calculateNNumber();
    }, [data]);

    const calculateNNumber = () => {
        const { R, FP, NE, FL, FI, FC, L } = data;
        const num =
            R * (FP / 100) * NE * (FL / 100) * (FI / 100) * (FC / 100) * L;

        setNNumber(Math.floor(num));
    };

    return (
        <div className={cn(className, classes.root)}>
            <VariablesIndicatorBar
                config={config}
                activeScreen={activeScreen}
                data={data}
                nNumber={nNumber}
            />
            <GameScreen
                config={config}
                activeScreen={activeScreen}
                setActiveScreen={setActiveScreen}
                setData={setData}
                nNumber={nNumber}
                resultsConfig={resultsConfig}
            />
        </div>
    );
};
