import cn from 'classnames';
import classes from './Onboarding.module.scss';
import Button from '@components/Button';
import { OnboardingPageModel } from '@models/pages';

export interface OnboardingProps {
    className?: string;
    config: OnboardingPageModel;
}

export const Onboarding = ({
    className,
    config,
}: OnboardingProps): JSX.Element => {
    return (
        <div className={cn(className, classes.root)}>
            <h1>{config.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: config.equation }} />
            <p>{config.breakdown}</p>
            <p>
                {config.starsLabel} {config.planetsLabel}{' '}
                {config.lifeformsLabel}
            </p>
            <p>{config.text}</p>
            <Button text={config.buttonText} link="/game" />
        </div>
    );
};
