import cn from 'classnames';
import classes from './Attractor.module.scss';
import { AttractorPageModel } from '@models/pages';
import Button from '@components/Button';

export interface AttractorProps {
    className?: string;
    config: AttractorPageModel;
}

export const Attractor = ({
    className,
    config,
}: AttractorProps): JSX.Element => {
    return (
        <div className={cn(className, classes.root)}>
            <h1>{config.title}</h1>
            {config.text.map((txt, idx) => (
                <p key={idx}>{txt}</p>
            ))}
            <Button text={config.buttonText} link="/onboarding" />
        </div>
    );
};
