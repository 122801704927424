import * as React from 'react';
import cn from 'classnames';
import ResultsGraph from '@components/ResultsGraph';
import Button from '@components/Button';
import { AnimatePresence, motion } from 'framer-motion';

import classes from './Results.module.scss';

import { ResultsPageModel } from '@models/pages';

export interface ResultsProps {
    className?: string;
    config: ResultsPageModel;
    nNumber?: number;
    activeScreen?: number;
    setActiveScreen: React.Dispatch<React.SetStateAction<number>>;
    setAllResultsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
    theMap?: any;
}

interface LoaderAnimationProps {
    nNumber?: number;
    duration?: number;
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoaderAnimation = ({
    nNumber,
    setShowLoading,
    duration = 5,
}: LoaderAnimationProps) => {
    React.useEffect(() => {
        const timer = setTimeout(() => setShowLoading(false), duration * 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={classes.loaderAnimation}>
            <div className={classes.loaderDatabase}>
                <AnimatePresence>
                    <motion.div
                        className={classes.loaderDatabaseText}
                        initial={{ x: '0%' }}
                        animate={{ x: 555 }}
                        transition={{
                            duration: duration * 0.66,
                            ease: [0.67, 0.83, 0.17, 0.99],
                        }}
                    >
                        {nNumber}
                    </motion.div>
                </AnimatePresence>
                <div className={classes.loaderDatabaseImage}>
                    <img src="/assets/database.png" alt="" />
                </div>
            </div>
            <div className={classes.loadingBar}>
                <AnimatePresence>
                    <motion.div
                        className={classes.loadingBarInner}
                        initial={{ x: '-100%' }}
                        animate={{ x: '0%' }}
                        transition={{
                            duration,
                            ease: [0.17, 0.67, 0.83, 0.67],
                        }}
                    />
                </AnimatePresence>
            </div>
        </div>
    );
};

export const Results = ({
    className,
    config,
    nNumber,
    setActiveScreen,
    setAllResultsUpdated,
    theMap,
}: ResultsProps): JSX.Element => {
    const [showLoading, setShowLoading] = React.useState(true);

    return (
        <div className={cn(className, classes.root)}>
            <div className={classes.content}>
                <div>
                    <h2>{config.title}</h2>
                    <p>
                        Your esimate suggests there might be{' '}
                        {nNumber?.toLocaleString()} intelligent civilisations to
                        contact you!
                    </p>
                    {showLoading ? (
                        <LoaderAnimation
                            nNumber={nNumber}
                            setShowLoading={setShowLoading}
                            duration={7}
                        />
                    ) : (
                        <ResultsGraph nNumber={nNumber} />
                    )}
                </div>
                {theMap}
            </div>
            {!showLoading && (
                <div className={classes.buttons}>
                    <Button
                        text={config.starsButtonText}
                        onClick={() => {
                            setActiveScreen(0);
                            setAllResultsUpdated(false);
                        }}
                    />

                    <Button
                        text={config.planetsButtonText}
                        onClick={() => {
                            setActiveScreen(3);
                            setAllResultsUpdated(false);
                        }}
                    />

                    <Button
                        text={config.lifeformsButtonText}
                        onClick={() => {
                            setActiveScreen(5);
                            setAllResultsUpdated(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
