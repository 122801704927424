import * as React from 'react';
import cn from 'classnames';

import Chart from 'chart.js/auto';

import classes from './ResultsGraph.module.scss';

export interface ResultsGraphProps {
    className?: string;
    nNumber?: number;
}

export const ResultsGraph = ({
    className,
    nNumber,
}: ResultsGraphProps): JSX.Element => {
    const myChart = React.useRef(null);
    let dataToPlot = [
        1000, 50000000, 0, 1000, 1350, 0, 1000, 50000, 1000, 0, 1000,
        50000000000,
    ];

    const localStorageResults: string =
        window.localStorage.getItem('allResults') || '[]';
    if (localStorageResults) {
        dataToPlot = JSON.parse(localStorageResults);
    }

    // 10,000 is Drake's current estimate
    dataToPlot.push(10000);

    dataToPlot = dataToPlot.map((data) => Number(data)).sort((a, b) => a - b);

    const resultsTally = {};
    for (const result of dataToPlot) {
        if (resultsTally[result]) {
            resultsTally[result] += 1;
        } else {
            resultsTally[result] = 1;
        }
    }

    const labels: string[] = [];
    const values: string[] = [];
    const backgroundColors: string[] = [];
    for (const [key, value] of Object.entries(resultsTally)) {
        if (key === '10000') {
            labels.push(
                `${Number(key).toLocaleString()} - Drake's current estimate`
            );
        } else if (key && key === nNumber?.toString()) {
            labels.push(`${Number(key).toLocaleString()} - Your estimate`);
        } else {
            labels.push(Number(key).toLocaleString());
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        values.push(value);

        if (key && key === nNumber?.toString()) {
            backgroundColors.push('rgba(0, 0, 0, 1)');
        } else if (key && key === '10000') {
            backgroundColors.push('rgba(0, 0, 0, 0.5)');
        } else {
            backgroundColors.push('rgba(0, 0, 0, 0.1)');
        }
    }

    React.useEffect(() => {
        const options = {
            maintainAspectRatio: true,
            scales: {
                y: {
                    display: false,
                    stacked: true,
                    grid: {
                        display: false,
                        color: 'rgba(255,99,132,0.2)',
                    },
                },
                x: {
                    grid: {
                        display: false,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
        };
        const data = {
            labels: labels,
            datasets: [
                {
                    label: '',
                    backgroundColor: backgroundColors,
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 2,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: values,
                },
            ],
        };

        if (myChart.current) {
            const theChart = new Chart(myChart.current, {
                type: 'bar',
                options,
                data,
            });

            return () => theChart.destroy();
        }
    }, [myChart, labels, values]);

    return (
        <div className={cn(className, classes.root)}>
            <div className={classes.label}>Not very optimistic</div>
            <canvas ref={myChart} id="myChart" width="500" height="230" />
            <div className={cn(classes.label, classes.labelRight)}>
                Very optimistic
            </div>
        </div>
    );
};
