import cn from 'classnames';
import classes from './VariablesIndicatorBar.module.scss';

import {
    GamePageModel,
    GamePageSectionVariable,
    GamePageVariables,
} from '@models/pages';

export interface VariablesIndicatorBarProps {
    className?: string;
    config: GamePageModel;
    activeScreen?: number;
    data?: {
        R: number;
        FP: number;
        NE: number;
        FL: number;
        FI: number;
        FC: number;
        L: number;
    };
    nNumber: number;
}

const VariablesIndicatorBarVariable = ({ variableName, data }: any) => {
    return (
        <div
            className={cn(
                classes.barVariable,
                `variable-${variableName.toUpperCase()}`
            )}
        >
            {variableName && (
                <p
                    dangerouslySetInnerHTML={{
                        __html: variableName,
                    }}
                />
            )}
            {data === 0 ? '?' : data}
        </div>
    );
};

const VariablesIndicatorBarSection = ({
    sectionName,
    sectionVariables,
    data,
}: any) => (
    <div className={classes.barSection}>
        <h2>{sectionName}</h2>
        <div className={classes.barSectionVariables}>
            {sectionVariables.map((sectionVar, idx) => (
                <VariablesIndicatorBarVariable
                    key={idx}
                    variableName={sectionVar.variableName}
                    data={data[sectionVar.variableName.toUpperCase()]}
                />
            ))}
        </div>
    </div>
);

export const VariablesIndicatorBar = ({
    className,
    config,
    activeScreen,
    data,
    nNumber,
}: VariablesIndicatorBarProps): JSX.Element => {
    console.log('VariablesIndicatorBar: ', { data }); // how to tell which variable is active...
    return (
        <div
            className={cn(
                className,
                classes.root,
                classes[`active-${activeScreen}`]
            )}
        >
            {config.variables?.map((variable, index) => (
                <VariablesIndicatorBarSection
                    key={index}
                    sectionName={variable.sectionName}
                    sectionVariables={variable.sectionVariables}
                    data={data}
                />
            ))}
            <div className={cn(classes.barSection, classes.barSectionNNumber)}>
                {nNumber === 0 ? '?' : nNumber}
            </div>
        </div>
    );
};
