import * as React from 'react';
import cn from 'classnames';
import classes from './Map.module.scss';

export interface MapProps {
    className?: string;
    activeScreen: number;
}

export const Map = ({ className, activeScreen }: MapProps): JSX.Element => {
    const canvas = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        console.log('mount');
    }, []);

    React.useEffect(() => {
        console.log({ activeScreen });
    }, [activeScreen]);

    React.useEffect(() => {
        if (canvas.current) {
            const image = new Image();
            image.src = '/assets/map-overlay.png';
            image.style.width = '420px';
            image.style.height = '420px';
            image.width = 420;
            image.height = 420;
            const context = canvas.current.getContext('2d');
            let boxWidth;
            let boxHeight;
            const rows = 30;
            const columns = 30;
            let counter = columns * rows * (activeScreen / 7);

            image.onload = function () {
                boxWidth = image.width / columns;
                boxHeight = image.height / rows;
                requestAnimationFrame(animate);
            };

            // eslint-disable-next-line no-inner-declarations
            function animate() {
                const x = Math.floor(Math.random() * columns);
                const y = Math.floor(Math.random() * rows);

                if (context) {
                    context.drawImage(
                        image,
                        x * boxWidth, // starting position of abscissa
                        y * boxHeight, // starting position of ordinate
                        boxWidth, // the width of the image
                        boxHeight, // image height
                        x * boxWidth, // place the X coordinate position of the image on the canvas
                        y * boxHeight, // place the Y coordinate position of the image on the canvas
                        boxWidth, // the width of the image to be used
                        boxHeight // height of image to use
                    );

                    counter++;

                    if (activeScreen === 8) {
                        context.drawImage(image, 0, 0);
                    } else if (
                        counter < columns * rows * ((activeScreen + 1) / 7) &&
                        activeScreen !== 0
                    ) {
                        requestAnimationFrame(animate);
                    }

                    // ORIGINAL
                    //If the module is rendered 90%, let the whole picture be displayed.
                    // if (counter > columns * rows * 0.9) {
                    //     context.drawImage(image, 0, 0);
                    // } else {
                    //     requestAnimationFrame(animate);
                    // }
                }
            }
        }
    }, [canvas, activeScreen]);

    return (
        <div className={cn(className, classes.root)}>
            <canvas
                ref={canvas}
                className={classes.canvas}
                id="myCanvas"
                width="402"
                height="402"
                style={{ backgroundImage: 'url(/assets/map.png)' }}
            ></canvas>
        </div>
    );
};
