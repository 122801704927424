import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Map from '@components/Map';
import Results from '@components/Results';

import classes from './GameScreen.module.scss';
import globals from '@styles/globals.module.scss';

import { GamePageModel, GamePageVariables } from '@models/pages';

export interface GameScreenProps {
    className?: string;
    config: GamePageModel;
    activeScreen: number;
    setActiveScreen: React.Dispatch<React.SetStateAction<number>>;
    setData: React.Dispatch<React.SetStateAction<any>>;
    nNumber?: number;
    theMap?: any;
    resultsConfig?: any;
}

const CurrentGameScreen = ({
    config,
    setActiveScreen,
    setData,
    screenConfigs,
    activeScreen,
    nNumber,
    theMap,
    allResultsUpdated,
    setAllResultsUpdated,
}: any) => {
    const [gameComplete, setGameComplete] = React.useState(false);
    const [finalScore, setFinalScore] = React.useState(null);
    // const navigate = useNavigate();

    const handleAnswer = (e) => {
        if (screenConfigs.length - 1 <= activeScreen++) {
            setGameComplete(true);
        } else {
            setActiveScreen((activeScreen: number) => (activeScreen += 1));
        }

        setData((data) => ({
            ...data,
            [e.target.dataset.variable]: Number(e.target.dataset.value),
        }));
    };

    React.useEffect(() => {
        if (gameComplete) {
            console.log('GAME COMPLETE');

            window.localStorage.setItem(
                'currentResult',
                JSON.stringify(nNumber)
            );

            setFinalScore(nNumber);
        }
    }, [nNumber, gameComplete]);

    React.useEffect(() => {
        if (!allResultsUpdated && gameComplete) {
            setFinalScore(nNumber);

            const localStorageResults: string =
                window.localStorage.getItem('allResults') || '[]';
            let allResults: string[] = [];
            if (localStorageResults) {
                allResults = JSON.parse(localStorageResults);
            }

            allResults.push(nNumber);
            window.localStorage.setItem(
                'allResults',
                JSON.stringify(allResults)
            );

            setAllResultsUpdated(true);
            setActiveScreen(8);
        }
    }, [finalScore]);

    // React.useEffect(() => {
    // if (allResultsUpdated) {
    // navigate('/results');
    // }
    // }, [allResultsUpdated]);

    return (
        <>
            <div className={classes.content}>
                <div className="">
                    <h2>{config.title}</h2>
                    <h3>{config.question}</h3>
                    <img src={`/assets/${config.image}`} alt="" />
                </div>
                <div className="">{theMap}</div>
            </div>
            <div className={classes.buttons}>
                {config.options.map((option) => (
                    <button
                        className={globals.button}
                        key={option}
                        onClick={(e) => handleAnswer(e)}
                        data-value={option}
                        data-variable={config.variableName.toUpperCase()}
                    >
                        {option}
                        {config.percentage && '%'}
                    </button>
                ))}
            </div>
        </>
    );
};

export const GameScreen = ({
    className,
    config,
    activeScreen,
    setActiveScreen,
    setData,
    nNumber,
    resultsConfig,
}: GameScreenProps): JSX.Element => {
    const [allResultsUpdated, setAllResultsUpdated] = React.useState(false);
    const screenConfigs = config.variables
        .map((variable) => variable.sectionVariables)
        .flat();

    const showGameScreen =
        screenConfigs && screenConfigs.length > 0 && !allResultsUpdated;

    return (
        <div className={cn(className, classes.root)}>
            {showGameScreen ? (
                <CurrentGameScreen
                    config={screenConfigs[activeScreen]}
                    setActiveScreen={setActiveScreen}
                    setData={setData}
                    screenConfigs={screenConfigs}
                    activeScreen={activeScreen}
                    nNumber={nNumber}
                    theMap={<Map activeScreen={activeScreen} />}
                    allResultsUpdated={allResultsUpdated}
                    setAllResultsUpdated={setAllResultsUpdated}
                />
            ) : (
                <Results
                    config={resultsConfig}
                    nNumber={nNumber}
                    setActiveScreen={setActiveScreen}
                    setAllResultsUpdated={setAllResultsUpdated}
                    theMap={<Map activeScreen={activeScreen} />}
                />
            )}
        </div>
    );
};
