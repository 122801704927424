import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Attractor from '@pages/Attractor';
import Onboarding from '@pages/Onboarding';
import Game from '@pages/Game';
import Results from '@components/Results';

import { AttractorPageModel } from '@models/pages';
import { OnboardingPageModel } from '@models/pages';
import { GamePageModel } from '@models/pages';
import { ResultsPageModel } from '@models/pages';

declare global {
    interface Window {
        electronAPI: {
            getExternalResourcePath: () => Promise<string>;
        };
        externalAssetPath: string;
    }
}

interface AppProps {
    config: {
        examplePage: {
            title: string;
            description: string;
        };
        attractorPage: AttractorPageModel;
        onboardingPage: OnboardingPageModel;
        game: GamePageModel;
        resultsPage: ResultsPageModel;
    };
    externalAssetPath: string;
}

const App = ({ config, externalAssetPath }: AppProps) => {
    const [nNumber, setNNumber] = React.useState(0);
    window.externalAssetPath = externalAssetPath;

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route
                        index
                        element={<Attractor config={config.attractorPage} />}
                    />
                    <Route
                        path="/onboarding"
                        element={<Onboarding config={config.onboardingPage} />}
                    />
                    <Route
                        path="/game"
                        element={
                            <Game
                                config={config.game}
                                nNumber={nNumber}
                                setNNumber={setNNumber}
                                resultsConfig={config.resultsPage}
                            />
                        }
                    />
                    <Route
                        path="*"
                        element={<Attractor config={config.attractorPage} />}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
